import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BasicList from "../components/BasicList"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Web-kehitys | Räätälöidyt palvelut ketterästi"
        description="Meiltä löytyy vahva tekninen osaaminen web-pohjaisten sovellusten kehittämiseen. Teemme web-kehitystä yritysten sisäiseen, kuin ulkoiseen käyttöön."
        pageImage={metaImg}
        pageUrl={links.webDev}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">Web-kehitys</span>
            </h1>
            <span className="block text-indigo-400">yrityksen tarpeisiin!</span>
          </HeroTitle>
        </Hero>
      </section>

      <section id="web-development">
        <DotSection dotPosition="top-right">
          <div className="grid lg:grid-cols-2 lg:space-x-5">
            <div>
              <Title tag="h2">Web-kehitys</Title>
              <Text>
                Web-kehitys on selainpohjaisia sovelluksia tuottavaa
                ohjelmistokehitystä. Suunnittelemme ja toteutamme web-kehitystä
                yritysten tarpeisiin. Kartoitamme alussa yrityksen
                liiketoiminnan ja suunnittelemme projektit tukemaan
                liiketoimintaa. Meiltä löytyy vahva tekninen osaaminen
                web-pohjaisten sovellusten kehittämiseen koko projektin
                elinkaaren aikana. Teemme web-kehitystä yritysten sisäiseen kuin
                ulkoiseen käyttöön.
              </Text>
              <Text>
                Web-kehitys ja sen suunnittelu ei ole helppoa, se on haastavaa
                mikä vaatii osaamista usealta eri saralta kuten, koodaus,
                design, sisällön suunnittelu, hakukoneoptimointi ja hallinta.
              </Text>
              <Text>
                Yleensä web-sovelluksia tehdään ja toteutetaan
                liiketoimintaprosessien tehostamiseksi, tueksi, tai edistämään
                palveluiden digitalisointia, mutta toteutamme myös web-kehitystä
                ihan startup meiningillä. Jokainen webkehitys projekti on
                erilainen ja jokaisessa projektissa on omat haasteensa, mutta
                jokaista niistä lähdemme toteuttamaan innolla, oli se sitten
                aiemmin kehitetyn ohjelmiston päivittämistä, korjaamista,
                jatkokehitystä tai ihan uuden verkkopohjaisen sovelluksen
                kehittäminen.
              </Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/5-right-trans-color.svg"
                alt="Profiili raketin ympärillä"
              />
            </div>
          </div>
        </DotSection>
      </section>

      <section id="choosing-right-language" className="bg-gray-800">
        <Container>
          <div className="grid lg:grid-cols-2 lg:space-x-5">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/2-left-trans.svg"
                alt="Applikaatio layoutit"
              />
            </div>
            <div className="mt-5 lg:mt-0">
              <Title tag="h2" textColor="text-white">
                Oikean ohjelmointikielen valitseminen on tärkeää
              </Title>
              <Text textColor="text-white">
                Web-kehityksessä käytämme ja hyödynnämme uusimpia web-kehityksen
                työkaluja, sekä ohjelmointitekniikoita. Kehitämme ja koulutamme
                itseämme aina kun siihen on mahdollisuus projektien välissä
                juuri siksi, että pystymme tarjoamaan sinulle parhaimman
                mahdollisen lopputuloksen.
              </Text>
              <Text textColor="text-white">
                Ohjelmointikieliä on satoja, ellei jopa tuhansia. Tunnetuimpia
                kieliä on esimerkiksi PHP, Java, Scala, Python, Ruby, Node ja
                SAS.
              </Text>
              <Text textColor="text-white">
                Oikean ohjelmointikielen valitseminen on tärkeää jokaiselle
                ohjelmistoprojektille. Toinen kieli voi olla esimerkiksi parempi
                ja edullisempi vaihtoehto tietylle asialle kun toiseen taas
                täytyisi käyttää tuplasti aikaa tai rahaa ja silti
                lopputuloksena voisi olla projektin huono suorituskyky.
                Webkehitys projekteissa valitsemme kustannustehokkaimmat ja
                projektin suorituskyvyn kannalta parhaiten sopivan
                ohjelmointikielen takaaksemme sen, että projekteilla on
                mahdollisuus toteutua.
              </Text>
            </div>
          </div>
        </Container>
      </section>

      <section id="planning">
        <Container>
          <Title tag="h2">Web-kehityksen suunnitteluprosessi</Title>
          <Text>
            Teemme tiiviisti yhteistyötä web-sovelluksen tilaajan kanssa ja
            teemme työmme joustavasti muutoksien suhteen. Jos sinulle tulee
            kesken kaiken mieleen uusia asia minkä haluaisit lisätä projektiin,
            kerro siitä rohkeasti meille ja teemme aikatauluihin tilaa jotta
            saamme sen sinne suunniteltua ja lisättyä.
          </Text>
          <Text>
            Teemme koko webkehitys projektin ajan testausta ja varmistamme, että
            kaikki tulee toimimaan niinkuin pitää. Valitsemme projektiin
            parhaiten sopivat teknologiat ja hyödynnämme niitä. Mielestämme on
            tärkeää keskustella tilaajan kanssa realistinen budjetti ja
            aikataulu, jottei yllätyskuluja syntyisi tai aikataulut venyisi.
          </Text>
          <Text>
            Olemme sitä mieltä, että jokainen webkehitys projekti on uniikki ja
            jokainen projekti tullaan rakentamaan siihen tarkoitukseen mihin sen
            käyttötarkoitus on suunniteltu. Rakennamme ohjelmistot sinua tai
            yritystäsi varten, emme itseämme.
          </Text>
        </Container>
      </section>

      <section id="our-process">
        <Container>
          <Title tag="h2">Meidän web-kehitys prosessimme</Title>
          <div className="mt-10">
            <SubTitle tag="h3">Konsultaatiotapaaminen:</SubTitle>
            <Text>
              Aloitamme projektin läpikäymisellä, suunnittelulla, budjetoinnilla
              ja aikataulutuksella.
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Prototyypin tekeminen:</SubTitle>
            <Text>
              Saamiemme tietojen perusteella teemme prototyypin projektista mitä
              käytämme runkona ohjelmiston kehittämisessä. Näytämme prototyypin
              sinulle, jotta voit kertoa meille mielipiteesi (tämä on
              mielestämme tärkeää, jotta lopputuloksena saataisiin sinunlaisesi
              tuote mihin olet tyytyväinen).
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Kehitys:</SubTitle>
            <Text>
              Aloitamme ohjelmiston kehittämisen ja pidämme sinut ajan tasalla
              tasaisin väliajoin, jotta sinä tiedät missä kohtaa projektia
              olemme ja pääset samalla seuraamaan kehitystä.
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Testaus:</SubTitle>
            <Text>
              Testaamme ohjelmistoa koko kehityksen ajan, mutta ennen
              käyttöönottoa ja ohjelmiston julkaisua testaamme tuotteen joko
              sisäisesti, tai sinun testiryhmän kanssa ohjelmiston kaikissa
              ympäristöissä löytääksemme mahdolliset bugit.
            </Text>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Käyttöönotto & julkaisu:</SubTitle>
            <Text>
              Lopulta kun testaus on loppunut ja bugit korjattu, on aika
              julkaista ohjelmisto ja ottaa se käyttöön. Kerromme vielä lopuksi
              tarvittavat ohjeistukset ohjelmiston ylläpitämiseen.
            </Text>
          </div>
        </Container>
      </section>

      <section id="agily" className="bg-gray-800 text-center">
        <Container>
          <Title tag="h2" textColor="text-white">
            Ketterä ohjelmistokehitys
          </Title>
          <Text textColor="text-white">
            Suosimme ketteriä menetelmiä ohjelmistokehityksessä ja toimimme aina
            asiakaslähtöisesti, jotta projekti tulee vastaamaan asiakkaan
            tarpeita. Ketterä ohjelmistokehitys minimoi riskejä ja antaa sinulle
            mahdollisuuden vaikuttaa projektin kulkuun koko
            ohjelmistosuunnittelun ajan.
          </Text>
        </Container>
      </section>

      <section id="uses-of-web-development">
        <Container>
          <Title tag="h2">Web-kehityksen käyttökohteita</Title>
          <div className="mt-10">
            <SubTitle tag="h3">Kustomoidut myynti ratkaisut:</SubTitle>
            <Text>
              Jos sinulla ei ole tuotteita/tarvetta verkkokauppaan, mutta
              tarvitset silti jonkinlaisen myynti ratkaisun, pystymme tuottamaan
              esimerkiksi erilaisia osto/myynti järjestelmiä ja räätälöimään ne
              juuri sinun tarpeisiin.
            </Text>
            <div className="mt-5">
              <Text>Kustomoitujen myynti ratkaisujen edut:</Text>
              <BasicList>
                <li>
                  Ohjelmisto räätälöidään juuri sinun yrityksesi tarpeisiin
                </li>
              </BasicList>
            </div>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Yrityksen sisäinen ohjelmisto:</SubTitle>
            <Text>
              Yrityksen sisäinen ohjelmisto voi olla esimerkiksi tuntiseuranta,
              työvuorolistat, periaatteessa mikä tahansa maan ja taivaan välillä
              mikä voidaan sähköistää. Yrityksen sisäisellä ohjelmalla pystyt
              sähköistämään, siirtämään ja muuttamaan liiketoimintaa nykyaikaan
              ja sen avulla tulet säästämään aikaa ja rahaa.
            </Text>
            <div className="mt-5">
              <Text>Yrityksen sisäisen ohjelmiston edut:</Text>
              <BasicList>
                <li>Säästät aikaa ja rahaa</li>
                <li>Saat muutettua liiketoimintasi nykyaikaan</li>
                <li>
                  Ohjelmisto räätälöidään juuri sinun yrityksesi tarpeisiin
                </li>
              </BasicList>
            </div>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Verkkokauppa:</SubTitle>
            <Text>
              Web-kehityksen ehkä tunnetuimmista ohjelmistoista on verkkokauppa.
              Verkkokauppa on erinomainen tapa tavoittaa uusia asiakkaita ja
              lisätä yrityksesi myyntiä. Verkkokaupan avulla voit myydä niin
              B2C- kuin B2B-tuotteita.
            </Text>
            <Text>
              Verkkokaupan kehityksessä on olennaista määrittää montako tuotetta
              verkkokauppaan viedään. Sen pohjalta pystymme arvioimaan, että
              voidaanko hintoja ja tuotetietoja ylläpitää manuaalisesti vai onko
              tarvetta suunnitella ja rakentaa integraatioita. Esimerkkinä
              integraatioista on toiminnanohjaus ja varastojärjestelmät. Näiden
              lisäksi on arvioitava mikä tai mitkä maksujärjestelmät sopivat
              parhaiten sinun verkkokaupparatkaisuun.
            </Text>
            <Text>
              Verkkokaupassa on kyse kokonaisuuden hallinnasta. Verkkokaupan
              julkaisemisen jälkeen on mietittävä tavoitteet ja esimerkiksi
              verkkokaupan digimarkkinointia.
            </Text>
            <div className="mt-5">
              <Text>Verkkokaupan edut:</Text>
              <BasicList>
                <li>
                  Verkkokauppa mahdollistaa uuden myyntikanavan yrityksellesi
                </li>
                <li>
                  Verkkokauppa laajentaa asiakaskuntaasi ja kasvattaa myyntiäsi
                </li>
                <li>Säästää aikaasi</li>
                <li>Verkkokaupan perustaminen on nopeaa</li>
                <li>
                  Verkkokauppa vahvistaa yrityksesi brändiä ja tunnettavuutta
                </li>
                <li>Yrityksesi voi säästää vuokra- ja palkkakustannuksissa</li>
                <li>Yrityksesi verkkokauppa on aina auki asiakkaita varten</li>
                <li>
                  Sinulla on mahdollisuus laittaa verkkokauppaan asiakaspalvelu
                  ja määrittää milloin asiakaspalvelija on paikalla vastaamassa
                  kysymyksiin
                </li>
              </BasicList>
            </div>
          </div>
          <div className="mt-10">
            <SubTitle tag="h3">Erinäiset SaaS-palvelut:</SubTitle>
            <Text>
              Mitä SaaS-palvelut tarkoittaa? SaaS lyhenne tulee englannin kielen
              sanoista Software as a Service. Se tarkoittaa sitä, ettei
              ohjelmistoja asenneta asiakkaan laitteistoihin vaan ne yleensä
              toimivat palveluntarjoajan ylläpitämällä palvelimella tietoverkon
              kautta. Yleensä tällöin ohjelmistoa käytetään internet-selaimen
              avulla. SaaS-palvelussa asiakas maksaa ohjelmistosta palvelumaksun
              muodossa eikä osta ohjelmistolisenssejä itselleen.
            </Text>
            <Text>
              Eli yksinkertaisesti SaaS-palvelulla tarkoitetaan pilvessä
              sijaitsevaa ohjelmistoa, jota palveluntarjoaja ylläpitää. Sen
              palvelut välitetään verkkoselaimen kautta, applikaationa tai
              näiden hybridinä. Näistä suosituin tapa välittää palvelut on
              verkkoselaimen kautta.
            </Text>
            <Text>
              SaaS on vuokrattava ohjelmisto. Yleensä sen käyttäjät eivät itse
              omista kyseistä ohjelmistoa vaan käyttöoikeus vuokrataan
              SaaS-palvelun tarjoajalta. Usein hinnoittelu perustuu
              käyttömäärään tai käyttöaikaan ja tavallisimpana
              hinnoittelumallina voidaan pitää kiinteää kuukausikohtaista
              hintaa.
            </Text>
            <Text>
              Lähtökohtaisesti SaaS-palvelut ovat standardoituja, minkä vuoksi
              niitä voidaan myydä/ostaa skaalautuvasti. SaaS-palveluissa on
              silti huomioitu asiakaskohtainen räätälöinti tarjoamalla
              palvelusta eri tuotevariaatioita asiakassegmenttien ja
              käyttötarpeiden mukaan.
            </Text>
            <Text>
              SaaS-palvelut ovat tulleet jäädäkseen. Ne ovat nykyaikaa ja sinun
              ei tarvitse enää käyttää asennettavia cd-levyjä, jotka vaativat
              suuria investointeja, lisenssimaksuja ja päivittämistä.
            </Text>
            <div className="mt-5">
              <Text>SaaS-palveluiden tuomiin etuihin kuuluu esimerkiksi:</Text>
              <BasicList>
                <li>Minimaaliset investointikustannukset</li>
                <li>Todella nopea käyttöönotto</li>
                <li>Ei tarvitse maksaa ohjelmistolisenssi maksuja</li>
                <li>
                  Asiakkaan ei tarvitse huolehtia ohjelmistojen päivityksistä
                </li>
                <li>Virustorjunta sekä tietoturva on aina ajan tasalla</li>
              </BasicList>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
